<template>
  <div class="p-2">
    <v-row class="mt-1">
      <v-col cols="12" lg="12" sm="12" md="12">
        <v-card color="teal darken-1 mb-2" dark>
          <v-card-text>

            <v-row class="mt-3 ml-2 mr-2">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-1 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :disabled="v.disabled"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>

        <v-card>
          <v-card-title
            >Surat Lembar Observasi Target Bulanan Pekerjaan</v-card-title
          >
          <v-card-text>
            <v-row class="mt-2 ml-1 mr-1">
              <v-col
                v-for="(v, i) in master.form1"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-1 pt-1 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form1[i] = e)"
                  :value="data.form1[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :disabled="v.disabled"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
            <div class="form-group">
              <div
                style="z-index: 999999"
                class="float-group btn-group-vertical"
                role="group"
                v-if="!!regid"
                aria-label="Vertical button group"
              >
                <v-btn
                  @click="postSave"
                  dark
                  color="primary"
                  medium
                  class="btn-block"
                  >Simpan Lembar Observasi</v-btn
                >
                <v-btn
                  dark
                  @click="addItem"
                  class="btn-block"
                  color="red darken-1"
                  >Tambah Item Observasi</v-btn
                >
                <v-btn color="deep-purple" @click="printlembarobservasi" dark class="btn-block">Print Lembar Observasi</v-btn>
                <v-btn color="deep-purple" @click="printjsa" dark class="btn-block">Print JSA</v-btn>
              </div>
            </div>
          </v-card-text>
        </v-card>
        <v-card class="mt-2">
          <v-card-title>Job Safety Analysis (JSA) </v-card-title>
          <v-card-text>
            <v-row class="mt-2 ml-1 mr-1">
              <v-col
                v-for="(v, i) in master.form2"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-1 mb-4 pt-1 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.form2[i] = e)"
                  :value="data.form2[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :disabled="v.disabled"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card class="mt-2">
          <v-card-title>Lembar Observasi Target Harian Pekerjaan</v-card-title>
          <v-card-subtitle>
            Jenis Pekerjaan (contoh: memanen, mengangkat beban 5 Kg,
            mengoperasikan mesin packing, dll)</v-card-subtitle
          >
          <v-card-text>
            <div id="table-scroll" class="table-scroll">
              <div class="table-wrap mt-1">
                <v-simple-table fixed-header width="100%">
                  <thead class="table-danger">
                    <tr>
                      <td style="vertical-align: middle" class="text-center">
                        #
                      </td>

                      <td style="vertical-align: middle" class="text-center">
                        <strong>Jenis pekerjaan</strong>
                      </td>
                      <td style="vertical-align: middle" class="text-center">
                        <strong>Keluhan nyeri (ada/tidak)</strong>
                      </td>
                      <td style="vertical-align: middle" class="text-center">
                        <strong>Capaian target kerja harian (%)</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in detail_obvserasi" :key="index">
                      <td style="vertical-align: middle">
                        <v-btn
                          @click="deleteItem(index)"
                          color="red darken-1"
                          dark
                          fab
                          small
                          ><v-icon small>mdi-delete</v-icon></v-btn
                        >
                      </td>

                      <td style="vertical-align: middle" width="40%">
                        <v-textarea
                          class="mt-3"
                          dense
                          outlined
                          v-model="item.jenisPekerjaan"
                          type="text"
                        ></v-textarea>
                      </td>
                      <td style="vertical-align: middle" width="40%">
                        <v-textarea
                          class="mt-3"
                          dense
                          outlined
                          type="text"
                          v-model="item.keluhanNyeri"
                        ></v-textarea>
                      </td>
                      <td style="vertical-align: middle" width="20%">
                        <v-textarea
                          class="mt-3"
                          dense
                          v-model="item.capaain"
                          outlined
                          type="text"
                        ></v-textarea>
                      </td>
                    </tr>
                  </tbody>
                </v-simple-table>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrlEmr,
  // eslint-disable-next-line no-unused-vars
  isEmpty
} from '@/plugins/supports'
// eslint-disable-next-line no-unused-vars
import Master from '../../data/OkupasiObservasi'
// eslint-disable-next-line no-unused-vars
import SmartWidget from '@/components/SmartWidget.vue'

export default {
  components: {
    // eslint-disable-next-line no-unused-vars, vue/no-unused-components
    SmartWidget
  },
  data () {
    return {
      regid: this.$route.query.registration_id,
      editor: ClassicEditor,
      master: Master,
      detail_obvserasi: [],
      id_generated: 0,
      user: JSON.parse(localStorage.getItem('user')),
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          cara_bayar: '',
          ruangan: '',
          kamar_kelas: '',
          jenis_kelamin: '',
          folio_id: '',
          sub_unit_id: '',
          reg_id: '',
          notes: ''
        },
        form1: {
          p1: '',
          p2: '',
          p3: '',
          p4: ''
        },
        form2: {
          p1: ''
        }
      },
      editorConfig: {
        // The configuration of the editor.
      },
      rules: {
        required: (value) => {
          return !isEmpty(value) || 'Tidak Boleh Kosong'
          // return true
        }
      }
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataPasien(this.$route.query.folio_id)
      this.pemakai = JSON.parse(localStorage.getItem('user'))
      this.getLembarObservasi()
    }
  },
  methods: {
    getDataPasien (folioId) {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrl + 'rawat-inap/get-detail-pasien',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.demografi = results
            this.data.demografi.jenis_kelamin = {
              value: results.jenis_kelamin,
              text: results.jenis_kelamin
            }
          } else {
          }
        },
        'JSON'
      )
    },
    postSave () {
      // if (this.user.identity_no !== '1403095205840009') {
      //   errorMsg('TIDAK BISA DISIMPAN JIKA TIDAK DOKTER OKUPASI')
      //   return
      // }

      // eslint-disable-next-line no-unused-vars
      const data = {
        reg_id: this.$route.query.registration_id,
        sub_unit_id: this.$route.query.sub_unit_id,
        folio_id: this.$route.query.folio_id,
        no_rm: this.$route.query.no_rm,
        data: this.data,
        dataObservasi: this.detail_obvserasi,
        created_by: this.pemakai.employee_name,
        employee_id: this.pemakai.employee_id
      }

      // eslint-disable-next-line no-undef
      $.post(
        baseUrlV2 + 'okupasi/simpan-lembar-observasi',
        data,
        (res) => {
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
            // this.listObat = []
            // this.banyakRacikan = []
            // this.getDataOkupasi()
          } else {
            errorMsg(msg + ' ' + JSON.parse(results))
          }
        },
        'JSON'
      )
      // console.log(JSON.stringify(data))
    },
    getLembarObservasi () {
      // eslint-disable-next-line no-unused-vars
      const folioId = this.$route.query.folio_id

      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'okupasi/get-lembar-observasi',
        { folio_id: folioId },
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            this.data.form1 = results.lembar.form1
            this.data.form2 = results.lembar.form2
            // console.log(results.lembar.form1)
            // eslint-disable-next-line no-unused-vars
            var ininya = this
            if (results.obs !== null) {
              results.obs.forEach((item, index) => {
                ininya.addItem(item)
              })
            }
          } else {
          }
        },
        'JSON'
      )
    },
    addItem (item) {
      console.log(item)
      localStorage.removeItem('isDetailPerwatanChange')
      this.id_generated = this.id_generated + 1
      console.log(this.id_generated)
      console.log(this.id_generated)
      if (item === null) {
        this.detail_obvserasi.push({
          item_id: this.id_generated, // item.item_id,
          tanggal: '',
          jenisPekerjaan: '',
          keluhanNyeri: '',
          capaain: ''
        })
      } else {
        this.detail_obvserasi.push({
          item_id: this.id_generated, // item.item_id,
          tanggal: item.tanggal,
          jenisPekerjaan: item.jenisPekerjaan,
          keluhanNyeri: item.keluhanNyeri,
          capaain: item.capaain
        })
      }
    },
    deleteItem (item) {
      this.detail_obvserasi.splice(item, 1)
    },
    printlembarobservasi () {
      window
        .open(
          baseUrlEmr +
            `laporan-medis/cetak-surat-lembar-observasi?folio_id=${this.$route.query.folio_id}`,
          '_blank'
        )
        .focus()
    },
    printjsa () {
      window
        .open(
          baseUrlEmr +
            `laporan-medis/cetak-jsa?folio_id=${this.$route.query.folio_id}`,
          '_blank'
        )
        .focus()
    }
  }
}
</script>

<style scoped>
.float-group {
  position: fixed;
  bottom: 45%;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
.table-scroll {
  position: relative;
  max-width: 1800px;
  margin: auto;
  overflow: hidden;
  /* border: 1px solid #000; */
}

.table-wrap {
  width: 100%;
  overflow: auto;
}

.table-scroll table {
  width: 100%;
  margin: auto;
  border-collapse: separate;
  border-spacing: 0;
}

.table-scroll th,
.table-scroll td {
  padding: 5px 10px;
  border: 0.5px solid #000;
  /* background: #fff; */
  white-space: nowrap;
  vertical-align: top;
}

.table-scroll thead,
.table-scroll tfoot {
  /* background: #f9f9f9; */
}

.clone {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.clone th,
.clone td {
  visibility: hidden;
}

.clone td,
.clone th {
  border-color: transparent;
}

.clone tbody th {
  visibility: visible;
  color: red;
}

.clone .fixed-side {
  border: 1px solid #000;
  background: #eee;
  visibility: visible;
}

.clone thead,
.clone tfoot {
  background: transparent;
}
</style>
