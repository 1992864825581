<template>
  <div class="p-2">
    <v-row class="mt-1">
      <v-col cols="12" lg="6" sm="6" md="6">
        <v-card color="teal darken-1 mb-2" dark>
          <v-card-text>
            <v-row class="mt-1 mb-0">
              <v-col
                v-for="(v, i) in master.demografi"
                :md="v.col ? v.col : 12"
                :lg="v.col ? v.col : 12"
                :sm="v.col ? v.col : 12"
                cols="12"
                class="mt-0 mb-1 pt-0 pb-3"
                :key="i"
              >
                <smart-widget
                  :comp="v.widget"
                  :sync-value="(e) => (data.demografi[i] = e)"
                  :value="data.demografi[i]"
                  vclass="c-text-field"
                  :data="v.data"
                  :label="v.label ? v.label : i.replaceAll('_', ' ')"
                  :rules="[rules.required]"
                />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card elevation="5" class="p-2">
          <v-card-title> Form Okupasi 👩🏻‍⚕️</v-card-title>
          <v-card-text>
            <div class="form-group">
              <label class="mb-3">🟦 <b>DI DAPATI BAHWA (Subjektif)</b></label>
              <ckeditor
                :editor="editor"
                v-model="formOkupasi.subjektif"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <div class="form-group">
              <label class="mb-3">🟦 <b>KONDISI PENYAKIT (Objektif)</b></label>
              <ckeditor
                :editor="editor"
                v-model="formOkupasi.objektif"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <v-divider class="mt-3"></v-divider>
            <v-select
              :items="[
                'Sesuai',
                'Tidak Sesuai',
                'Belum Sesuai',
                'Bukan Kasus KK',
              ]"
              v-model="formOkupasi.kasus"
              dense
              label="🚗 LAPORAN KASUS KECELAKAAN"
              outlined
            ></v-select>
            <div v-if="formOkupasi.diagnosa" class="mb-3">
              <v-chip
                v-for="(indexIcd, i) of formOkupasi.diagnosa"
                :key="i"
                class="m-1"
                close
                color="red"
                @click:close="deleteItem(i)"
                text-color="white"
              >
                {{ indexIcd.text }}
              </v-chip>
            </div>
            <v-autocomplete
              v-model="formOkupasi.diagnosa"
              return-object
              item-text="text"
              item-value="text"
              multiple
              outlined
              dense
              deletable-chips
              :cache-items="true"
              placeholder="Start typing to Search"
              :items="items"
              chips
              :search-input.sync="searchIcd"
              label="🏥 Diagnosa Utama"
            >
            </v-autocomplete>

            <div class="form-group mt-2">
              <label class="mb-3">🟦 <b>DIAGNOSA LAINNYA</b></label>
              <ckeditor
                :editor="editor"
                v-model="formOkupasi.diagnosa_lainnya"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <v-divider></v-divider>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6" sm="6" md="6">
        <v-card elevation="5" class="p-2">
          <v-card-title> Form Okupasi 👩🏻‍⚕️</v-card-title>
          <v-card-text>
            <v-select
              :items="statusLaikKerja"
              dense
              item-text="text"
              item-value="id"
              v-model="formOkupasi.laik"
              label="📕 STATUS KELAIKAN KERJA"
              outlined
            ></v-select>
            <div v-if="formOkupasi.laik">
              <v-chip color="red" text-color="white" class="mb-3">
                {{ formOkupasi.laikValue.text }}
              </v-chip>
            </div>

            <div class="form-group">
              <label class="mb-3">🟦 <b>Rekomendasi</b></label>
              <ckeditor
                :editor="editor"
                v-model="formOkupasi.rekomendasi"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <div class="form-group">
              <label class="mb-3">🟦 <b>Resiko</b></label>
              <ckeditor
                :editor="editor"
                v-model="formOkupasi.resiko"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <div class="form-group">
              <label class="mb-3">🟦 <b>Toleransi</b></label>
              <ckeditor
                :editor="editor"
                v-model="formOkupasi.toleransi"
                :config="editorConfig"
              ></ckeditor>
            </div>
            <v-divider></v-divider>

            <v-text-field
              label="🚀 Impairment"
              dense
              v-model="formOkupasi.impairment"
              placeholder="Impairment"
              outlined
            ></v-text-field>
            <v-text-field
              label="🚀 Disability"
              dense
              v-model="formOkupasi.disability"
              placeholder="Disability"
              outlined
            ></v-text-field>
            <v-text-field
              label="🚀 Handicap"
              dense
              v-model="formOkupasi.handicap"
              placeholder="Handicap"
              outlined
            ></v-text-field>
          </v-card-text>
        </v-card>
        <div class="form-group">
          <div
            style="z-index: 999999"
            class="float-group btn-group-vertical"
            role="group"
            v-if="!!regid"
            aria-label="Vertical button group"
          >
            <v-btn
              color="orange"
              medium
              dark
              @click="printLaikKerja"
              class="btn-block"
              >Cetak Laik Kerja 📑</v-btn
            ><v-btn
              color="red"
              medium
              dark
              @click="getAssemen"
              class="btn-block"
              >Copy Dari Resume 📑</v-btn
            ><v-btn
              color="blue"
              medium
              dark
              @click="simpanOkupasi"
              class="btn-block"
              >Simpan Okupasi 📕
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  baseUrlEmr
} from '@/plugins/supports'
import Master from '../../data/lembar-konsultasi'
import SmartWidget from '@/components/SmartWidget.vue'

export default {
  components: {
    SmartWidget
  },
  data () {
    return {
      regid: this.$route.query.registration_id,
      editor: ClassicEditor,
      master: Master,
      user: JSON.parse(localStorage.getItem('user')),
      formOkupasi: {
        subjektif: '',
        objektif: '',
        diagnosa_lainnya: '',
        rekomendasi: '',
        resiko: '',
        impairment: '',
        handicap: '',
        disability: '',
        toleransi: '',
        laik: '',
        kasus: '',
        diagnosa: ''
      },
      searchIcd: '',
      items: [],
      statusLaikKerja: [
        { id: '10', text: 'Sehat untuk bekerja' },
        { id: '11', text: 'Sehat untuk bekerja dengan catatan' },
        { id: '12', text: 'Sehat untuk bekerja dengan pembatasan pekerjaan' },
        { id: '13', text: 'Sementara Tidak Sehat kerja' },
        { id: '14', text: 'Tidak Sehat kerja' }
      ],
      editorConfig: {
        // The configuration of the editor.
      },
      data: {
        demografi: {
          nama_pasien: '',
          no_rekam_medis: '',
          tanggal_lahir: '',
          jenis_kelamin: '',
          cara_bayar: '',
          reg_id: '',
          folio_id: ''
        }
      },
      rules: {
        required: (value) => {
          // return !isEmpty(value) || 'Tidak Boleh Kosong'
          return true
        }
      }
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataOkupasi()
      this.getDataPasien(this.$route.query.no_rm)
    }
  },
  methods: {
    simpanOkupasi () {
      // eslint-disable-next-line no-unused-vars
      const reg = {
        reg_id: this.$route.query.registration_id,
        sub_unit_id: this.$route.query.sub_unit_id,
        folio_id: this.$route.query.folio_id
      }

      if (this.user.identity_no !== '1403095205840009') {
        errorMsg('TIDAK BISA DISIMPAN JIKA TIDAK DOKTER OKUPASI')
        return
      }

      // eslint-disable-next-line no-unused-vars
      const data = {
        reg: reg,
        data: this.formOkupasi
      }

      // eslint-disable-next-line no-undef
      $.post(
        baseUrlV2 + 'okupasi/simpan-okupasi',
        data,
        (res) => {
          const { con, msg, results } = res
          if (con) {
            successMsg(msg)
            // this.listObat = []
            // this.banyakRacikan = []
            this.getDataOkupasi()
          } else {
            errorMsg(msg + ' ' + JSON.parse(results))
          }
        },
        'JSON'
      )
      console.log(JSON.stringify(data))
    },
    getDataOkupasi () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'okupasi/get-data-okupasi?reg_id=' + this.regid,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res
          if (con) {
            // this.statusLaikKerja
            console.log(this.statusLaikKerja.id + ' STATUS')
            this.formOkupasi = results
          } else {
            errorMsg(msg)
          }
        },
        'JSON'
      )
    },
    deleteItem (index) {
      // console.log('hantu' + index)
      this.formOkupasi.diagnosa.splice(index, 1)
      // this.vModel.splice(index, 1)
    },
    getDataPasien (v) {
      apiKedua
        .get('emr/detail-pasien?rm=' + v, {})
        .then((response) => {
          this.dataPatient = response.data.results
          console.log('HASIL RESPONSE ' + response)

          // this.dataPatient = JSON.parse(localStorage.getItem('pasien'))
          // this.folioYangDipakai = JSON.parse(
          //   localStorage.getItem('folioIdYangDipakai')
          // )
          this.data.demografi = this.dataPatient
          this.data.demografi.jenis_kelamin = {
            value: this.dataPatient.jenis_kelamin,
            text: this.dataPatient.jenis_kelamin
          }
          this.data.demografi.folio_id = this.$route.query.folio_id
          this.data.demografi.reg_id = this.$route.query.registration_id
          this.data.demografi.sub_unit_id = this.$route.query.sub_unit_id

          this.patient_name = response.data.results.nama_pasien
          var today = new Date()
          var time =
            today.getHours() +
            ':' +
            today.getMinutes() +
            ':' +
            today.getSeconds()
          console.log(time)
          this.data.inputan_dokter.jam = time
        })
        .catch(function (error) {
          console.log(error)
        })
    },
    getAssemen (v) {
      // console.table(v)
      apiKedua
        .get(
          'emr/get-asesmen-keperawatan?folio_id=' +
            this.$route.query.folio_id +
            '&reg_id=' +
            this.$route.query.registration_id +
            '&sub_unit_id=' +
            this.$route.query.sub_unit_id
        )
        .then((res) => {
          // console.log(res)
          const response = res.data.results.data
          const con = res.data.con
          // eslint-disable-next-line no-undef
          const pemeriksaan = LZString.decompressFromBase64(response)
          const pemeriksaanCom = JSON.parse(pemeriksaan)
          console.log(pemeriksaanCom + 'HASIL')
          var dataJson = pemeriksaanCom

          // console.log(response.pemeriksaan + 'JSON')

          if (con) {
            this.formOkupasi.subjektif = this.appendTechnique(
              this.formOkupasi.subjektif,
              dataJson.isian_dokter.subjektif
            )
            this.formOkupasi.objektif = this.appendTechnique(
              this.formOkupasi.objektif,
              dataJson.isian_dokter.objektif
            )
            successMsg('BERHASIL MENGAMBIL DATA DARI ASSEMENT')
          } else {
            errorMsg('GAGAL MENGAMBIL DATA DARI ASSEMENT!')
            // alert('data')
          }
          // console.log(response)
        })
    },
    appendTechnique (satu, dua) {
      // var a = 'how you are'
      if (satu.indexOf(dua) > -1) {
        // jika sudah ada / contained
        return satu
      }
      if (satu !== '' && satu !== dua) {
        // jika tidak kosong dan tidak inputan yang sama dgn skrng
        // alert('sadads')
        return dua + '\n' + satu // append
      } else {
        return dua
      }
    },
    printLaikKerja () {
      window
        .open(
          baseUrlEmr +
            `laporan-medis/cetak-laik-kerja?id_reg=${this.$route.query.registration_id}`,
          '_blank'
        )
        .focus()
    }
  },
  watch: {
    vModel (n, o) {
      this.syncValue(n)
    },
    cvalue (n, o) {
      // console.log(n + 'INI NGGAK ADA ISINYA ???')
      this.vModel = n
    },
    emitInput () {},
    searchIcd (val) {
      if (val.length >= 2) {
        // eslint-disable-next-line no-undef
        $.get(baseUrlV2 + 'poliklinik/icd?q=' + val, (res) => {
          this.items = res.results
        })
      }
    }
  }
}
</script>

<style>
.float-group {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}

.float {
  position: fixed;
  bottom: 160px;
  right: 40px;
  color: #fff;
  text-align: center;
  z-index: 100;
}
</style>
