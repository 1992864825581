<template>
  <v-container fluid class="mb-5 mt-3">
    <v-row>
      <v-col cols="12" lg="12" sm="12" md="12">
        <v-tabs v-model="tab" centered style="font-size: 12px" class="mt-2">
          <v-tab>Form Okupasi</v-tab>
          <v-tab>Lembar Observasi / JSA</v-tab>
          <v-tab>List Surat Okupasi</v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <FormOkupasi />
          </v-tab-item>
          <v-tab-item>
            <Observasi />
          </v-tab-item>
          <v-tab-item>
            <ListOkupasi />
          </v-tab-item>

        </v-tabs-items>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import FormOkupasi from './FormOkupasi.vue'
import ListOkupasi from './ListOkupasi.vue'
import Observasi from './Observasi.vue'
export default {
  components: {
    FormOkupasi,
    // eslint-disable-next-line vue/no-unused-components
    ListOkupasi,
    Observasi
  },
  data () {
    return {
      tab: ''
    }
  }
}
</script>
