export default {
  demografi: {
    nama_pasien: {
      label: 'Nama Pasien',
      widget: 'wtext',
      data: null,
      disabled: true,
      col: 5
    },
    no_rekam_medis: {
      label: 'No.Rekam Medis',
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 4
    },
    tanggal_lahir: {
      label: 'Tangga Lahir',
      widget: 'wdatenormal',
      data: null,
      disabled: true,

      col: 3
    },
    cara_bayar: {
      label: 'Cara Bayar',
      widget: 'wtext',
      disabled: true,

      data: [],
      col: 4
    },
    dpjp_utama: {
      widget: 'wtext',
      data: null,
      disabled: true,

      label: 'Dokter DPJP Utama',
      col: 4
    },

    kamar_kelas: {
      widget: 'wtext',
      data: null,
      label: 'Kamar/Kelas',
      disabled: true,

      col: 4
    },
    jenis_kelamin: {
      widget: 'wradio',
      disabled: true,

      data: [
        { text: 'Laki-Laki', value: 'laki-laki' },
        { text: 'Wanita', value: 'wanita' }
      ],
      col: 3
    },

    folio_id: {
      widget: 'wtext',
      data: null,
      disabled: true,

      col: 3,
      label: 'No.Folio'
    },
    sub_unit_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      col: 3,
      label: 'Sub Unit Dokter'
    },
    reg_id: {
      widget: 'wtext',
      disabled: true,

      data: null,
      label: 'No Reg',
      col: 3
    }
  },
  form1: {
    label1: {
      label: 'Kepada Yth Pimpinan',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p1: {
      label: ' ',
      widget: 'wtext',
      data: [],
      // disabled: true,

      col: 9
    },
    label2: {
      label: 'Di Tempat',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p2: {
      label: ' ',
      widget: 'wtext',
      data: [],
      // disabled: true,
      col: 9
    },
    label3: {
      label: 'Pekerjaan Pasien',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p3: {
      label: ' ',
      widget: 'wtext',
      data: [],
      // disabled: true,
      col: 9
    },
    label4: {
      label: 'Hasil Evaluasi',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p4: {
      label: ' ',
      widget: 'wtextarearow',
      data: [],
      // disabled: true,
      col: 9
    }
    // ruangan: {
    //   widget: 'wtext',
    //   data: null,
    //   label: 'Dokter',
    //   disabled: true,

    //   col: 4
    // },
  },
  form2: {
    label1: {
      label: 'JSA',
      widget: 'wlabelkiri',
      data: [],
      col: 3
    },
    p1: {
      label: ' ',
      widget: 'wtextarearow',
      data: [],
      // disabled: true,

      col: 9
    }
  }
}
