<template>
  <v-container fluid class="mb-2">
    <v-row align="center" justify="center">
      <v-col cols="12" lg="8" md="8" sm="8">
        <v-card class="mx-auto" max-width="800">
          <v-toolbar color="primary" dark>

            <v-toolbar-title>List Okupasi</v-toolbar-title>

            <v-spacer></v-spacer>

          </v-toolbar>

          <v-list two-line>
            <v-list-item-group
              v-model="selected"
              active-class="pink--text"
              multiple
            >
              <template v-for="(item, index) in listOkupasi">
                <v-list-item :key="item.id_okupasi">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title
                        v-text="item.no_surat"
                        class="mb-2"
                      ></v-list-item-title>

                      <v-list-item-subtitle
                        class="text--primary mb-2"

                        v-text="item.status_kelaikan_kerjaa"
                      ></v-list-item-subtitle>

                      <v-list-item-subtitle
                        v-html="item.rekomendasi"
                      ></v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-action>
                      <v-btn medium color="primary" @click="liatView(item.no_daftar)">Laik Kerja</v-btn>
                    </v-list-item-action>
                  </template>
                </v-list-item>

                <v-divider
                  v-if="index < items.length - 1"
                  :key="index"
                ></v-divider>
              </template>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
        <v-dialog v-model="showDialogBdrs" max-width="1280px">
          <v-card>
            <v-card-title class="headline"> Form Okupasi </v-card-title>
            <v-card-text>
              <div id="hasil"></div>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
  </v-container>
</template>
<script>
// eslint-disable-next-line no-unused-vars
import SmartWidget from '@/components/SmartWidget.vue'
// eslint-disable-next-line no-unused-vars
import {
  // eslint-disable-next-line no-unused-vars
  apiKedua,
  // eslint-disable-next-line no-unused-vars
  successMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrl,
  // eslint-disable-next-line no-unused-vars
  errorMsg,
  // eslint-disable-next-line no-unused-vars
  baseUrlV2,
  // eslint-disable-next-line no-unused-vars
  isEmpty
} from '../../plugins/supports'
export default {
  data () {
    return {
      listOkupasi: [],
      selected: [],
      showDialogBdrs: false,
      items: [
        {
          action: '15 min',
          headline: 'Brunch this weekend?',
          subtitle: 'I\'ll be in your neighborhood doing errands this weekend. Do you want to hang out?',
          title: 'Ali Connors'
        },
        {
          action: '2 hr',
          headline: 'Summer BBQ',
          subtitle: 'Wish I could come, but I\'m out of town this weekend.',
          title: 'me, Scrott, Jennifer'
        },
        {
          action: '6 hr',
          headline: 'Oui oui',
          subtitle: 'Do you have Paris recommendations? Have you ever been?',
          title: 'Sandra Adams'
        },
        {
          action: '12 hr',
          headline: 'Birthday gift',
          subtitle: 'Have any ideas about what we should get Heidi for her birthday?',
          title: 'Trevor Hansen'
        },
        {
          action: '18hr',
          headline: 'Recipe to try',
          subtitle: 'We should eat this: Grate, Squash, Corn, and tomatillo Tacos.',
          title: 'Britta Holt'
        }
      ]
    }
  },
  created () {
    if (this.$route.query.no_rm != null) {
      this.getDataOkupasi()
    }
  },
  mounted () {
    if (this.$route.query.no_rm != null) {
      this.getDataOkupasi()
    }
  },
  methods: {
    getDataOkupasi () {
      // eslint-disable-next-line no-undef
      $.get(
        baseUrlV2 + 'okupasi/list-okupasi?no_rm=' + this.$route.query.no_rm,
        (res) => {
          // eslint-disable-next-line no-unused-vars
          const { con, msg, results } = res

          if (con) {
            this.listOkupasi = results
          } else {
            this.listOkupasi = []
          }
        },
        'JSON'
      )
    },
    liatView (item) {
      this.showDialogBdrs = true
      // eslint-disable-next-line no-undef
      $.ajax({
        url: baseUrlV2 + 'rme/okupasi-view?id_reg=' + item,
        // eslint-disable-next-line no-undef
        type: 'GET',
        success: (result) => {
          // // console.log(result);
          // eslint-disable-next-line no-undef
          $('#hasil').html(result)
          // // eslint-disable-next-line no-undef
          // $('#mymodal2').modal({ show: true })
        }
      })
    }
  }
}
</script>
